import React, { useState, useEffect, useRef } from "react";
import { BurgerMenuPane } from "../hamburgerMenu/BurgerMenuPane";
import { Main } from "./Main";
import { useLocation } from "react-router-dom";
import { FreeFormBlock } from "./blocks/FreeFormBlock";
import { ButtonsBlock } from "./blocks/ButtonsBlock";
import { TableBlock } from "./blocks/TableBlock";
import { SpacerBlock } from "./blocks/SpacerBlock";
import { InformationalBlock } from "./blocks/InformationalBlock";
import { SearchBlock } from "./blocks/SearchBlock";
import { WebAppConfigs } from "../../onsight-plus";
import { getUpdatedLayouts } from "../../helpers";
import { updateConfigs } from "../../helpers";
import { Buffer } from "buffer";

export const Container = (props) => {
  const { search } = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useState("");
  const urlSearchParamsRef = useRef("");
  urlSearchParamsRef.current = urlSearchParams;
  const [searchParam, setSearchParam] = useState("");
  const searchParamRef = useRef("");
  searchParamRef.current = searchParam;

  const [initialContainerLoad, setInitialContainerLoad] = useState(true);
  const [layouts, setLayouts] = useState([]);
  const layoutsRef = useRef([]);
  layoutsRef.current = layouts;

  const [layoutId, setLayoutId] = useState(null);
  const [configId, setConfigId] = useState(null);
  const configIdRef = useRef(null);
  configIdRef.current = configId;

  const [searchValue, setSearchValue] = useState("");


  const [blockConfig, setBlockConfig] = useState([]);
  const blockConfigRef = useRef([]);
  blockConfigRef.current = blockConfig;

  const [initialBlockConfigFromAPI, setInitialBlockConfigFromAPI] = useState([]);
  const [initialMappingInputFromAPI, setInitialMappingInputFromAPI] = useState([]);
  const [initialValuesFromAPI, setInitialValuesFromAPI] = useState([]);

  const [dataSourceJson, setDataSourceJson] = useState([]);
  const dataSourceJsonRef = useRef([]);
  dataSourceJsonRef.current = dataSourceJson;

  const [loadContent, setLoadContent] = useState(true);

  const [initialSearchValue, setInitialSearchValue] = useState("");
  const initialSearchValueRef = useRef("");
  initialSearchValueRef.current = initialSearchValue;

  const [initalHamBurgerLoad, setInitalHamBurgerLoad] = useState(false);

  const [dataSourcesLoaded, setDataSourcesLoaded] = useState(false)

  const onLayoutChangeBlock = async (_layouts) => {
    let newLayouts;
    newLayouts = await onLayoutChange(_layouts, true);
    if (newLayouts !== undefined) {
      layoutsRef.current = newLayouts;
      const letNewLayouts = newLayouts.map((nl) => nl);
      setLayouts(letNewLayouts);

      const letBlockConfigs = blockConfigRef.current.map((bc) => bc);
      setBlockConfig([...letBlockConfigs]);
    }
  };

  let onLayoutChange = async (newLayouts, isBlock = false) => {
    if (newLayouts !== undefined) {
      const refLayouts = layoutsRef.current.map((cl) => {
        return { w: cl.w, h: cl.h, x: cl.x, y: cl.y, i: cl.i };
      });
      const _layouts = newLayouts.map((cl) => {
        return { w: cl.w, h: cl.h, x: cl.x, y: cl.y, i: cl.i };
      });
      if (JSON.stringify(refLayouts) !== JSON.stringify(_layouts) || isBlock) {
        let updatedLayout;
        updatedLayout = await getUpdatedLayouts(newLayouts, props.isAdmin);

        if (updatedLayout !== undefined && configIdRef.current) {
          layoutsRef.current = updatedLayout;
          setLayouts(updatedLayout);
          await updateConfigs(props.pageId, updatedLayout, "UILayouts", configIdRef.current, props.isAdmin);

          return updatedLayout;
        }
      }
    }
  };

  const prevContentRef = useRef();
  useEffect(() => {
    const contain = document.getElementById("navbar-container-main");
    if (prevContentRef.current !== undefined && contain.innerHTML !== prevContentRef.current) {
      getUpdatedLayouts(layoutsRef.current, setLayouts, props.isAdmin);
    } else {
      prevContentRef.current = contain.innerHTML;
    }
  });

  const handleClick = (value, event) => {
    setSearchValue(value);

    const newBlocks = blockConfigRef.current.map((p, i) => {
      let dataSourceContent = []
      if (p.props.children.props.dataSourceSelectedId !== undefined && value !== null) {
        let dataSourceContentArray = dataSourceJsonRef.current.filter(dsc => {
          return (dsc.id === p.props.children.props.dataSourceSelectedId)
        })        
        if (dataSourceContentArray.length > 0) {
          for (const [key, val] of Object.entries(dataSourceContentArray[0].data)) {
            if (key.toUpperCase() === value.toUpperCase()) {
              dataSourceContent = val
              break;
            }
          }
        }
      }
      return p.props.children.props.blockType === "table" ? (
        <div id={p.key} key={p.key}>
          <TableBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            blockStyles={p.props.children.props.blockStyles}
            dataSource={p.props.children.props.dataSource}
            dataSourceSelectedId={p.props.children.props.dataSourceSelectedId}
            extensionSelectedId={p.props.children.props.extensionSelectedId}
            id={p.key}
            mapping={p.props.children.props.mapping}
            content={dataSourceContent}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            onLayoutChangeBlock={onLayoutChangeBlock}
            rowsToDisplay={p.props.children.props.rowsToDisplay}
          />
        </div>
      ) : p.props.children.props.blockType === "informational" ? (
        <div id={p.key} key={p.key}>
          <InformationalBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            blockStyles={p.props.children.props.blockStyles}
            dataSource={p.props.children.props.dataSource}
            dataSourceSelectedId={p.props.children.props.dataSourceSelectedId}
            id={p.key}
            mapping={p.props.children.props.mapping}
            content={dataSourceContent}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            onLayoutChangeBlock={onLayoutChangeBlock}
          />
        </div>
      ) : p.props.children.props.blockType === "buttons" ? (
        <div id={p.key} key={p.key}>
          <ButtonsBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            blockStyles={p.props.children.props.blockStyles}
            id={p.key}
            mapping={p.props.children.props.mapping}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            onLayoutChangeBlock={onLayoutChangeBlock}
          />
        </div>
      ) : p.props.children.props.blockType === "freeform" ? (
        <div id={p.key} key={p.key}>
          <FreeFormBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            id={p.key}
            mapping={p.props.children.props.mapping}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            onLayoutChangeBlock={onLayoutChangeBlock}
          />
        </div>
      ) : p.props.children.props.blockType === "search" ? (
        <div id={p.key} key={p.key}>
          <SearchBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            blockStyles={p.props.children.props.blockStyles}
            id={p.key}
            mapping={p.props.children.props.mapping}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            handleClick={handleClick}
            onLayoutChangeBlock={onLayoutChangeBlock}
            initialSearchValue={initialSearchValueRef.current}
          />
        </div>
      ) : p.props.children.props.blockType === "spacer" ? (
        <div id={p.key} key={p.key}>
          <SpacerBlock
            keyId={p.props.children.props.keyId}
            blockName={p.props.children.props.blockName}
            id={p.key}
            mapping={p.props.children.props.mapping}
            allLayouts={layoutsRef.current}
            setLayouts={setLayouts}
            blockType={p.props.children.props.blockType}
            onLayoutChangeBlock={onLayoutChangeBlock}
          />
        </div>
      ) : (
        <div></div>
      );
    });

    setBlockConfig(newBlocks);
  };

  const setContentHandler = async (searchParamVal, contentVal, dataSourceId, isApi = false) => {
    let decodedContent;
      try {
        decodedContent = isApi ? contentVal : JSON.parse(Buffer.from(contentVal, "base64"));
      } catch (ex) {
        console.warn("JSON data source file is invalid!", ex);
      }
  
      searchParamRef.current = searchParamVal;
      setSearchParam(searchParamVal);
  
      let newContent = dataSourceJson;
  
      newContent.push({ id: dataSourceId, data: decodedContent })
      dataSourceJsonRef.current = newContent
      setDataSourceJson(newContent)
  };

  const loadInitialSearch = () => {
    if (loadContent) {
      setLoadContent(false);
      if (initialSearchValueRef.current !== "") {
        handleClick(initialSearchValueRef.current);
      }
    }
  };

  const loadConfigs = async (reloadConfigs = false) => {
    let configId = props.configId;
    let pageId = props.pageId;

    const appConfigs = [];
    if (configId !== undefined && configId !== null && pageId !== undefined && pageId !== null) {
      const uiLayouts = await WebAppConfigs.get(pageId, configId, "UILayouts");
      const uiConfigs = await WebAppConfigs.get(pageId, configId, "UIConfigs");

      appConfigs.push(uiLayouts);
      appConfigs.push(uiConfigs);

      setLayoutId(uiLayouts.id);
      configIdRef.current = configId;
      setConfigId(configId);
    } else {
      const allConfigs = await WebAppConfigs.getAll(pageId);
      const uiLayout = allConfigs.find((uic) => uic.type === "UILayouts");
      const uiConfig = allConfigs.find((uic) => uic.type === "UIConfigs");

      appConfigs.push(uiLayout);
      appConfigs.push(uiConfig);

      setLayoutId(uiLayout.id);
      configIdRef.current = uiConfig.relationalId;
      setConfigId(uiConfig.relationalId);
    }

    if (!dataSourcesLoaded) {
      const initialContentFromApi = appConfigs.find((ac) => ac.type === "UIConfigs");
      if (initialContentFromApi !== undefined && initialContentFromApi !== null) {
        const dataSourceBlock = initialContentFromApi.objectValue["UIInputValues"].find((iv) => iv.id === "canvasDataSourceId");
        if (dataSourceBlock !== undefined && dataSourceBlock !== null && dataSourceBlock.inputs !== undefined) {
          await loadDataSources(dataSourceBlock)
        }
      }
    }

    if (initialContainerLoad || reloadConfigs) {
      let pageLayout = appConfigs.find((ac) => ac.type === "UILayouts")?.objectValue;
      let pageConfigs = appConfigs.find((ac) => ac.type === "UIConfigs")?.objectValue;

      let initialLayoutsFromApiTemp = [];
      let initialBlockConfigFromAPITemp = [];
      let initialMappingInputFromAPITemp = [];
      let initialValuesFromAPITemp = [];

      if (pageLayout !== undefined) {
        initialLayoutsFromApiTemp = pageLayout["UILayouts"];
      }

      if (pageConfigs !== undefined) {
        initialBlockConfigFromAPITemp = pageConfigs["UIBlockConfigs"];
        initialMappingInputFromAPITemp = pageConfigs["UIMappingInputs"];
        initialValuesFromAPITemp = pageConfigs["UIInputValues"];
      }

      let initialSearchParam = "";
      if (initialValuesFromAPITemp.find((iv) => iv.blockType === "search") !== undefined) {
        initialSearchParam = initialValuesFromAPITemp.find((iv) => iv.blockType === "search").inputs[0][1].queryParam;
        let searchParams = new URLSearchParams(search);
        urlSearchParamsRef.current = searchParams;
        let initialSearchVal = urlSearchParamsRef.current.get(initialSearchParam);
        if (searchParams !== null) {
          initialSearchValueRef.current = initialSearchVal;
          setInitialSearchValue(initialSearchVal);
        }
      }

      layoutsRef.current = initialLayoutsFromApiTemp;
      setLayouts(initialLayoutsFromApiTemp);
      setInitialBlockConfigFromAPI(initialBlockConfigFromAPITemp);

      setInitialMappingInputFromAPI(initialMappingInputFromAPITemp);
      setInitialValuesFromAPI(initialValuesFromAPITemp);

      blockConfigRef.current = initialBlockConfigFromAPITemp;
      setBlockConfig(initialBlockConfigFromAPITemp);

      getAdminSettings(initialValuesFromAPITemp);

      setInitialContainerLoad(false);
      setInitalHamBurgerLoad(true);
      loadInitialSearch();

      return initialValuesFromAPITemp
    }
  };

  const loadDataSources = async (dataSourceBlock) => {
    await Promise.all(
    dataSourceBlock.inputs.map(async (dsbi) => {
      if (dsbi[1].dataSourceTitle !== undefined && dsbi[1].dataSourceTitle !== '') {
        await setContentHandler(dsbi[1].dataSourceTitle, dsbi[1].dataSourceFile, dsbi[1].dataSourceId);
      } else if (dsbi[1].dataSourceEndpoint !== undefined) {
        try {
          const endpointUrl = new URL(dsbi[1].dataSourceEndpoint);
          const data = await WebAppConfigs.getData(endpointUrl.href);
          await setContentHandler(endpointUrl.href, data, dsbi[1].dataSourceId, true);
        } catch (ex) {
          console.warn("Invalid URL for data source", dsbi[1].dataSourceEndpoint, ex);
        }
      }
    })).then(() => {
      setDataSourcesLoaded(true);
    })
  }

  useEffect(() => {
    if (initialContainerLoad) {
      loadConfigs()
    }
  }, [dataSourceJson, loadContent, initialContainerLoad]);

  const getAdminSettings = (blockArr) => {
    let newBlock;
    let keyId = "";
    let newBlocks = blockConfigRef.current;
    let newBlocksToRender = [];
    blockArr.forEach((p) => {
      newBlock = undefined;
      if (newBlocks !== undefined && newBlocks.length !== 0 && newBlocks.find((nb) => nb.key === p.key)) {
        if (p.blockType === "table") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <TableBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    blockStyles={p.blockStyles}
                    dataSource={p.dataSource}
                    dataSourceSelectedId={p.dataSourceSelectedId}
                    extensionSelectedId={p.extensionSelectedId}
                    id={p.key}
                    mapping={p.inputs}
                    content={""}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                    rowsToDisplay={p.rowsToDisplay}
                  />
                </div>
              );
            }
          });
        } else if (p.blockType === "informational") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks = newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <InformationalBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    blockStyles={p.blockStyles}
                    dataSource={p.dataSource}
                    dataSourceSelectedId={p.dataSourceSelectedId}
                    id={p.key}
                    mapping={p.inputs}
                    content={""}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                  />
                </div>
              );
            }
          });
        } else if (p.blockType === "buttons") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <ButtonsBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    blockStyles={p.blockStyles}
                    id={p.key}
                    mapping={p.inputs}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                  />
                </div>
              );
            }
          });
        } else if (p.blockType === "freeform") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks = newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <FreeFormBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    id={p.key}
                    mapping={p.inputs}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                  />
                </div>
              );
            }
          });
        } else if (p.blockType === "search") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <SearchBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    blockStyles={p.blockStyles}
                    id={p.key}
                    mapping={p.inputs}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    handleClick={handleClick}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                    initialSearchValue={initialSearchValueRef.current}
                  />
                </div>
              );
            }
          });
        } else if (p.blockType === "spacer") {
          newBlock = newBlocks.find((nb) => nb.key === p.key);
          newBlocks.forEach((nb) => {
            if (newBlock.key === nb.key) {
              keyId = `content-${p.key}`;
              newBlocksToRender.push(
                <div id={p.key} key={p.key}>
                  <SpacerBlock
                    keyId={keyId}
                    blockName={p.blockName}
                    id={p.key}
                    mapping={p.inputs}
                    allLayouts={layoutsRef.current}
                    setLayouts={setLayouts}
                    blockType={p.blockType}
                    onLayoutChangeBlock={onLayoutChangeBlock}
                  />
                </div>
              );
            }
          });
        }
      } else {
        if (p.blockType === "table") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <TableBlock
                keyId={keyId}
                blockName={p.blockName}
                blockStyles={p.blockStyles}
                dataSource={p.dataSource}
                dataSourceSelectedId={p.dataSourceSelectedId}
                extensionSelectedId={p.extensionSelectedId}
                id={p.key}
                mapping={p.inputs}
                content={p.content}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                onLayoutChangeBlock={onLayoutChangeBlock}
                rowsToDisplay={p.rowsToDisplay}
              />
            </div>
          );
        } else if (p.blockType === "informational") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <InformationalBlock
                keyId={keyId}
                blockName={p.blockName}
                blockStyles={p.blockStyles}
                dataSource={p.dataSource}
                dataSourceSelectedId={p.dataSourceSelectedId}
                id={p.key}
                mapping={p.inputs}
                content={p.content}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                onLayoutChangeBlock={onLayoutChangeBlock}
              />
            </div>
          );
        } else if (p.blockType === "buttons") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <ButtonsBlock
                keyId={keyId}
                blockName={p.blockName}
                blockStyles={p.blockStyles}
                id={p.key}
                mapping={p.inputs}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                onLayoutChangeBlock={onLayoutChangeBlock}
              />
            </div>
          );
        } else if (p.blockType === "freeform") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <FreeFormBlock
                keyId={keyId}
                blockName={p.blockName}
                id={p.key}
                mapping={p.inputs}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                onLayoutChangeBlock={onLayoutChangeBlock}
              />
            </div>
          );
        } else if (p.blockType === "search") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <SearchBlock
                keyId={keyId}
                blockName={p.blockName}
                blockStyles={p.blockStyles}
                id={p.key}
                mapping={p.inputs}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                handleClick={handleClick}
                onLayoutChangeBlock={onLayoutChangeBlock}
                initialSearchValue={initialSearchValueRef.current}
              />
            </div>
          );
        } else if (p.blockType === "spacer") {
          keyId = `content-${p.key}`;
          newBlock = (
            <div id={p.key} key={p.key}>
              <SpacerBlock
                keyId={keyId}
                blockName={p.blockName}
                id={p.key}
                mapping={p.inputs}
                allLayouts={layoutsRef.current}
                setLayouts={setLayouts}
                blockType={p.blockType}
                onLayoutChangeBlock={onLayoutChangeBlock}
              />
            </div>
          );
        }
        if (newBlock !== undefined) {
          newBlocksToRender.push(newBlock);
        }
      }
    });

    blockConfigRef.current = newBlocksToRender;
    setBlockConfig(newBlocksToRender);
  };

  return (
    <div>
      {props.isAdmin ? (
        <BurgerMenuPane
          pageId={props.pageId}
          getAdminSettings={getAdminSettings}
          initialBlockConfig={initialBlockConfigFromAPI}
          initialMappingInput={initialMappingInputFromAPI}
          initialValuesFromAPI={initialValuesFromAPI}
          layoutId={layoutId}
          configId={configId}
          setContentHandler={setContentHandler}
          isProd={props.isProd}
          isAdmin={props.isAdmin}
          initialLoad={initalHamBurgerLoad}
          setInitialLoad={setInitalHamBurgerLoad}
          loadConfigs={loadConfigs}
        />
      ) : (
        <></>
      )}
      <Main
        isAdmin={props.isAdmin}
        onLayoutChange={onLayoutChange}
        searchValue={searchValue}
        layouts={layouts}
        handleClick={handleClick}
        blockConfig={blockConfigRef.current}
      />
    </div>
  );
};
