import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { MappingInput } from "./MappingInput";
import { StyleAccordion } from "./StyleAccordion";
import { Search } from "./Search"
import { Extensions as ExtensionsApi } from "../../onsight-plus"

export const ChildContent = (props) => {
  const [id, setId] = useState(0);
  const setIdRef = useRef(0);
  setIdRef.current = id;
  const [blockNameValue, setBlockNameValue] = useState("");
  const [dataSourceValue, setDataSourceValue] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [extensions, setExtensions] = useState([])
  const [selectedExtension, setSelectedExtension] = useState("");
  const [rowsToDisplay, setRowsToDisplay] = useState(0)

  if (props !== undefined && props.mappingInput !== undefined && props.mappingInput.length !== 0) {
    props.mappingInput.find((mi) => mi.no === props.blockId).type = props.selected;
  }

  const addMappingInput = (type, selected) => {
    let highestNoInput = 0;
    if (props.mappingInput.length !== 0) {
      const listOfMappingInputNo = props.mappingInput[0].inputs.map((i) => Number(i.props.id.replace("_mappingInput", "")));
      if (listOfMappingInputNo.length !== 0) {
        highestNoInput = Math.max(...listOfMappingInputNo);
      }
    }

    const newId = Number(highestNoInput) + 1;
    const newMappingInputId = newId + "_mappingInput";
    const keyId = String.fromCharCode(newId + 64).toLowerCase();

    setIdRef.current = newId;
    setId(newId);

    if (props.mappingInput.length !== 0) {
      props.mappingInput
        .find((mi) => mi.no === props.blockId)
        .inputs.push(
          <MappingInput
            setTextInputOptionsHandler={props.setTextInputOptionsHandler}
            textInputValues={props.textInputValues}
            type={type}
            key={keyId}
            id={newMappingInputId}
            removeMappingInput={props.removeMappingInput}
            blockId={props.blockId}
            no={newId}
            initialValuesFromAPI={props.initialValuesFromAPI}
          />
        );
      props.setMappingInput(props.mappingInput);
    } else {
      const mi = (
        <MappingInput
          type={type}
          key={keyId}
          id={newMappingInputId}
          removeMappingInput={props.removeMappingInput}
          blockId={props.blockId}
          no={newId}
          initialValuesFromAPI={props.initialValuesFromAPI}
        />
      );
      const newMappingInput = { inputs: [mi], no: props.blockId, parent_id: props.blockId + "_ParentDDLcard", type: String(selected) };
      props.setMappingInput([newMappingInput], true);
    }
  };

  const getDataSource = (selected) => {
    setSelectedDataSource(selected.target.value);
  };

  const loadExtensions = async () => {
    try {
        const exts = await ExtensionsApi.getAll();
        setExtensions(exts);
    } catch (error) {
        console.log(error);
    }
  };

  const getExtension = (selected) => {
    setSelectedExtension(selected.target.value);
  };

  const updateRowsToDisplay = (evt) => {
    setRowsToDisplay(evt.target.value)
  }

  useEffect(() => {
    loadExtensions();
  }, []);

  useEffect(() => {
    if (initialLoad) {
      if (props.initialValuesFromAPI !== undefined) {
        let valuesForBlock = props.initialValuesFromAPI.find((iv) => iv.id === String(props.blockId));
        if (valuesForBlock !== undefined) {
          if (valuesForBlock.blockType === "table" || valuesForBlock.blockType === "informational") {
            setBlockNameValue(valuesForBlock.blockName);
            setDataSourceValue(valuesForBlock.dataSource);
            if (valuesForBlock.dataSource === 'Extension') {
              setSelectedExtension(valuesForBlock.extensionSelectedId)
            } 
            setSelectedDataSource(valuesForBlock.dataSourceSelectedId)
            setRowsToDisplay(valuesForBlock.rowsToDisplay)
          } else if (valuesForBlock.blockType === "search") {
            setDataSourceValue(valuesForBlock.dataSource);
          }

          let numArr = [];
          props.initialValuesFromAPI.forEach((ibc) => {
            numArr.push(ibc.id);
          });
          let newBlockId = Math.max(...numArr) + 1;

          setId(newBlockId);
          setInitialLoad(false);
        }
      }
    }
  }, [initialLoad, props.blockId, props.initialValuesFromAPI, props.initialBlockConfig]);

  useEffect(() => {
    if (props.initialValuesFromAPI !== undefined) {
      let availableDSs = props.initialValuesFromAPI.filter((iv) => iv.id === "canvasDataSourceId");
      if (availableDSs.length !== 0) {
        if (availableDSs !== undefined && availableDSs !== null) {
          let inputs = availableDSs[0].inputs;
          let mappedInputs = inputs.map((i) => {
            return {
              ["name"]: i[1].dataSourceName,
              ["file"]: i[1].dataSourceFile,
              ["title"]: i[1].dataSourceTitle,
              ["url"]: i[1].dataSourceEndpoint,
              ["id"]: i[1].dataSourceId,
            };
          });
          setDataSources(mappedInputs);
        }
      }
    }
  }, [props.initialValuesFromAPI])

  useEffect(() => {
    if (props.mappingInput.length !== 0) {
      props.mappingInput
        .find((mi) => mi.no === props.blockId)
        .inputs.map((i) => {
          const nmi = (
            <MappingInput
              type={i.props.type}
              key={i.key}
              id={i.props.id}
              removeMappingInput={i.props.removeMappingInput}
              blockId={i.props.blockId}
              no={i.props.no}
              initialValuesFromAPI={props.initialValuesFromAPI}
              reloadConfigs={props.reloadConfigs}
              setReloadConfigs={props.setReloadConfigs}
            />
          );
          return nmi
        });
      props.setMappingInput(props.mappingInput);
    }
  }, [props.initialValuesFromAPI])


  return props.selected === "1" ? (
    <Card className="my-1 childContent blockTable" id={props.blockId} key={props.blockKeyId}>
      <div className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>
        <StyleAccordion isChanged={props.isChanged} setIsChanged={props.setIsChanged} initialValuesFromAPI={props.initialValuesFromAPI} blockId={props.blockId} isProd={props.isProd} selected={props.selected} />
        
        <Form.Label>Pagination</Form.Label>
        <Form.Select value={rowsToDisplay} id="rowsToDisplay" onChange={updateRowsToDisplay} type="fieldselect" className="fieldselect" required>
          <option key={0} value={0}>None</option>
          <option key={10} value={10}>10 Rows</option>
          <option key={25} value={25}>25 Rows</option>
          <option key={50} value={50}>50 Rows</option>
          <option key={100} value={100}>100 Rows</option>
        </Form.Select>

        <Form.Group className="mb-1">
          <Form.Label>Data Source</Form.Label>
          <Form.Select value={selectedDataSource} onChange={getDataSource} type="fieldselect" className="fieldselect" required>
            <option value="">Select Data Source</option>
            {dataSources.map((ds) => {
              return (
                <option key={ds.id} value={ds.id}>
                  {ds.name}
                </option>
              );
            })}
            <option value={"extension_" + props.blockId}>Extension</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
        </Form.Group>

        {selectedDataSource !== "extension_" + props.blockId ? 
        <>
          <Form.Group className="mb-1">
            <Form.Control className={`${props.isProd ? "mb-1 d-none selectedDataSource" : "mb-1 selectedDataSource"}`} readOnly type="datasource" defaultValue={selectedDataSource} placeholder="Selected Data Source" />
            <Form.Control className="mb-1 dataSource" type="datasource" defaultValue={dataSourceValue} placeholder="Enter Data Source" required />
            <Form.Control.Feedback type="invalid">Please enter the data source path!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Control type="titlename" className="blockName" defaultValue={blockNameValue} placeholder="Enter Name" required />
            <Form.Control.Feedback type="invalid">Please enter a valid name!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 mappingInput">
            <Form.Label>Mapping</Form.Label>
            <Row className="px-0 py-1 pl-1">
              <Col sm="auto">
                <Button type="button" onClick={() => addMappingInput("blockTable", props.selected)} id={"addNewBtn_" + props.blockId} className="btn btn-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </Button>
              </Col>
            </Row>
            {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
          </Form.Group>
        </> : 
        <>
        <Form.Group>
          <Form.Label>Extension</Form.Label>
          <Form.Select value={selectedExtension} onChange={getExtension} type="fieldselect" className="fieldselect" required>
            <option value="">Select Extension</option>
            {extensions.map((ext) => {
              return (
                <option key={ext.id} value={ext.id}>
                  {ext.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
        </Form.Group>

          <Form.Group className="mb-1">
            <Form.Control className={`${props.isProd ? "mb-1 d-none selectedDataSource" : "mb-1 selectedDataSource"}`} readOnly type="datasource" defaultValue={selectedDataSource} placeholder="Selected Data Source" />
            <Form.Control className={`${props.isProd ? "mb-1 d-none dataSource" : "mb-1 dataSource"}`} type="datasource" readOnly defaultValue="Extension" />
            <Form.Control className={`${props.isProd ? "mb-1 d-none selectedExtension" : "mb-1 selectedExtension"}`} readOnly type="datasource" defaultValue={selectedExtension} />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Control type="titlename" className="blockName" defaultValue={blockNameValue} placeholder="Enter Name" required />
            <Form.Control.Feedback type="invalid">Please enter a valid name!</Form.Control.Feedback>
          </Form.Group>
        </>}
        
      </div>
    </Card>
  ) : props.selected === "2" ? (
    <Card className="my-1 childContent blockInformational" id={props.blockId} key={props.blockKeyId}>
      <div className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>Data Source</Form.Label>
          <Form.Select value={selectedDataSource} onChange={getDataSource} type="fieldselect" className="fieldselect" required>
            <option value="">Select Data Source</option>
            {dataSources.map((ds) => {
              return (
                <option key={ds.id} value={ds.id}>
                  {ds.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select an option!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Control className={`${props.isProd ? "mb-1 d-none selectedDataSource" : "mb-1 selectedDataSource"}`} readOnly type="datasource" defaultValue={selectedDataSource} placeholder="Selected Data Source" />
          <Form.Control className="mb-1 dataSource" type="datasource" defaultValue={dataSourceValue} placeholder="Enter Data Source" required />
          <Form.Control.Feedback type="invalid">Please select a data source!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Control type="titlename" className="blockName" defaultValue={blockNameValue} placeholder="Enter Name" />
        </Form.Group>

        <StyleAccordion isChanged={props.isChanged} setIsChanged={props.setIsChanged} initialValuesFromAPI={props.initialValuesFromAPI} blockId={props.blockId} isProd={props.isProd} selected={props.selected} />
        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockInformational", props.selected)} id={"addNewBtn_" + props.blockId} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </div>
    </Card>
  ) : props.selected === "3" ? (
    <Card className="my-1 childContent blockButtons" id={props.blockId} key={props.blockKeyId}>
      <div className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>
        <StyleAccordion isChanged={props.isChanged} setIsChanged={props.setIsChanged} initialValuesFromAPI={props.initialValuesFromAPI} blockId={props.blockId} isProd={props.isProd} selected={props.selected} />
        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockButtons", props.selected)} id={"addNewBtn_" + props.blockId} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </div>
    </Card>
  ) : props.selected === "4" ? (
    <Card className="my-1 childContent blockFreeForm" id={props.blockId} key={props.blockKeyId}>
      <div className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockFreeForm", props.selected)} id={"addNewBtn_" + props.blockId} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </div>
    </Card>
  ) : props.selected === "5" ? (
    <Card className="my-1 childContent blockSearch" id={props.blockId} key={props.blockKeyId}>
      <div className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>
        <Form.Group className="mb-3">
          <StyleAccordion isChanged={props.isChanged} setIsChanged={props.setIsChanged} initialValuesFromAPI={props.initialValuesFromAPI} blockId={props.blockId} isProd={props.isProd} selected={props.selected} />
        </Form.Group>
        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Search initialValuesFromAPI={props.initialValuesFromAPI} blockId={props.blockId} id={props.id}></Search>
        </Form.Group>
      </div>
    </Card>
  ) : props.selected === "6" ? (
    <Card className="my-1 childContent blockSpacer" id={props.blockId} key={props.blockKeyId}>
      <div className={`${props.isProd ? "p-3 d-none" : "p-3"}`}>
        <Form.Group className="mb-3">
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>
      </div>
    </Card>
  ) : (
    <Card className="mt-1" id={props.blockId} key={props.blockKeyId} />
  );
};
