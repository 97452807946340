import React, { useState, useEffect } from "react";
import { Form, Button, Container as BSContainer } from "react-bootstrap";

export const SearchBlock = (props) => {
  const [vin, setVin] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  const [backgroundColorButton, setBackgroundColorButton] = useState("");
  const [fontSizeButton, setFontSizeButton] = useState("");
  const [fontColorButton, setFontColorButton] = useState("");
  const [fontFamilyButton, setFontFamilyButton] = useState("");
  const [italicButton, setItalicButton] = useState("");
  const [boldButton, setBoldButton] = useState("");
  const [underlineButton, setUnderlineButton] = useState("");

  const [backgroundColorSearchField, setBackgroundColorSearchField] = useState("");
  const [fontSizeSearchField, setFontSizeSearchField] = useState("");
  const [fontColorSearchField, setFontColorSearchField] = useState("");
  const [fontFamilySearchField, setFontFamilySearchField] = useState("");
  const [justificationSearchField, setJustificationSearchField] = useState("");
  const [italicSearchField, setItalicSearchField] = useState("");
  const [boldSearchField, setBoldSearchField] = useState("");
  const [underlineSearchField, setUnderlineSearchField] = useState("");

  const handleChange = (event) => {
    setVin(event.target.value);
  };

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  }, []);

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For SearchBlock", props.id);
  }

  useEffect(() => {
    if (initialLoad) {
      if (props.initialSearchValue !== null && props.initialSearchValue !== "" && props.initialSearchValue !== undefined) {
        setVin(props.initialSearchValue);
        setInitialLoad(false);
      }
    }
  }, [props.searchParams, initialLoad]);

  useEffect(() => {
    if (props.blockStyles !== undefined) {
      props.blockStyles.forEach((bs) => {
        if (bs["bgColorTitle"] !== undefined) {
          setBackgroundColorButton(bs["bgColorTitle"]);
        } else if (bs["bgColorSearchField"] !== undefined) {
          setBackgroundColorSearchField(bs["bgColorSearchField"]);
        } else if (bs["fontSizeInputTitle"] !== undefined) {
          setFontSizeButton(bs["fontSizeInputTitle"]);
        } else if (bs["fontColorInputTitle"] !== undefined) {
          setFontColorButton(bs["fontColorInputTitle"]);
        } else if (bs["fontFamilyInputTitle"] !== undefined) {
          setFontFamilyButton(bs["fontFamilyInputTitle"]);
        } else if (bs["italicTitle"] !== undefined) {
          setItalicButton(bs["italicTitle"]);
        } else if (bs["boldTitle"] !== undefined) {
          setBoldButton(bs["boldTitle"]);
        } else if (bs["underlineTitle"] !== undefined) {
          setUnderlineButton(bs["underlineTitle"]);
        } else if (bs["fontColorInputHeader"] !== undefined) {
          setFontColorSearchField(bs["fontColorInputHeader"]);
        } else if (bs["fontSizeInputHeader"] !== undefined) {
          setFontSizeSearchField(bs["fontSizeInputHeader"]);
        } else if (bs["fontFamilyInputHeader"] !== undefined) {
          setFontFamilySearchField(bs["fontFamilyInputHeader"]);
        } else if (bs["alignmentHeader"] !== undefined) {
          setJustificationSearchField(bs["alignmentHeader"]);
        } else if (bs["italicHeader"] !== undefined) {
          setItalicSearchField(bs["italicHeader"]);
        } else if (bs["boldHeader"] !== undefined) {
          setBoldSearchField(bs["boldHeader"]);
        } else if (bs["underlineHeader"] !== undefined) {
          setUnderlineSearchField(bs["underlineHeader"]);
        }
      });
    }
  }, [props.blockStyles]);

  let buttonStyle = {
    fontSize: fontSizeButton,
    color: fontColorButton,
    fontFamily: fontFamilyButton,
    fontStyle: italicButton ? "italic" : "none",
    fontWeight: boldButton ? "bold" : "none",
    textDecoration: underlineButton ? "underline" : "none",
    backgroundColor: backgroundColorButton,
  };

  let searchFieldStyle = {
    fontSize: fontSizeSearchField,
    color: fontColorSearchField,
    fontFamily: fontFamilySearchField,
    textAlign: justificationSearchField,
    fontStyle: italicSearchField ? "italic" : "none",
    fontWeight: boldSearchField ? "bold" : "none",
    textDecoration: underlineSearchField ? "underline" : "none",
    backgroundColor: backgroundColorSearchField,
  };

  return (
    <BSContainer className="mt-2 p-0 d-flex" id={props.keyId} role="search">
      <Form.Control
        value={vin}
        onChange={handleChange}
        className="me-2"
        style={searchFieldStyle}
        type="search"
        placeholder={props.mapping.length !== 0 ? props.mapping[0][1].searchText : ""}
        aria-label="Search"
      />
      <Button style={buttonStyle} onClick={props.handleClick.bind(this, vin)} className="btn btn-search ml-auto col-auto" type="submit">
        {props.mapping.length !== 0 ? props.mapping[0][1].buttonTitle : ""}
      </Button>
    </BSContainer>
  );
};
